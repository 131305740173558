var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "home"
  }, [!_vm.isActive ? _c('div', {
    staticClass: "d-flex justify-content-center mt-3"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-sm",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.start.apply(null, arguments);
      }
    }
  }, [_vm._v(" Odśwież ")])]) : _vm._e(), !_vm.monitoring ? _c('div', {
    staticClass: "text-center my-5"
  }, [_c('i', {
    staticClass: "fas fa-circle-notch fa-spin"
  })]) : _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-6"
  }, [_c('h5', {
    staticClass: "text-center my-4"
  }, [_vm._v("Wejście")]), _c('camera', {
    attrs: {
      "place": "entrance"
    }
  })], 1), _c('div', {
    staticClass: "col-lg-6"
  }, [_c('h5', {
    staticClass: "text-center my-4"
  }, [_vm._v("Taras")]), _c('camera', {
    attrs: {
      "place": "terrace"
    }
  })], 1), _c('div', {
    staticClass: "col-lg-12"
  }, [_c('h5', {
    staticClass: "text-center my-4"
  }, [_vm._v("Garaż")]), _c('camera', {
    attrs: {
      "place": "garage"
    }
  })], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }