<template>
  <div>
    <img :src="src" v-if="src" alt="" style="width: 100%" />
    <div v-else>No Preview</div>
  </div>
</template>
<script>
export default {
  name: 'Camera',
  props: {
    place: {
      type: String,
      default: 'entrance'
    }
  },

  computed: {
    monitoring() {
      return this.$store.state.monitoring
    },

    src() {
      return this.monitoring[this.place] || null
    }
  }
}
</script>
