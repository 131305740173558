var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.src ? _c('img', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "src": _vm.src,
      "alt": ""
    }
  }) : _c('div', [_vm._v("No Preview")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }