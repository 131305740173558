<template>
  <div class="home">
    <div class="d-flex justify-content-center mt-3" v-if="!isActive">
      <button class="btn btn-primary btn-sm" @click.prevent="start">
        Odśwież
      </button>
    </div>

    <div class="text-center my-5" v-if="!monitoring">
      <i class="fas fa-circle-notch fa-spin"></i>
    </div>

    <div v-else>
      <div class="row">
        <div class="col-lg-6">
          <h5 class="text-center my-4">Wejście</h5>
          <camera place="entrance" />
        </div>
        <div class="col-lg-6">
          <h5 class="text-center my-4">Taras</h5>
          <camera place="terrace" />
        </div>

        <div class="col-lg-12">
          <h5 class="text-center my-4">Garaż</h5>
          <camera place="garage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Camera from "@/components/monitoring/Camera";

export default {
  name: "MonitoringView",

  data() {
    return {
      isActive: true,
      interval: null,
    };
  },

  components: {
    Camera,
  },

  computed: {
    ...mapState(["socketId", 'monitoring']),
  },

  methods: {
    /**
     * Ping api to start sending data form cameras
     */
    start() {
      window.mBus.socket.emit("api", { type: "cam", socketId: this.socketId });
    },
  },

  mounted() {
    this.start();

    this.intervel = setInterval(() => {
      const diffTime = 1000 * 5; // 5 seconds
      const { updated } = this.monitoring || {};
      this.isActive = parseInt(updated) + diffTime > Date.now();

      this.start();
    }, 2000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
